import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';
import Countdown from 'react-countdown';

import * as countdownStyle from 'styles/components/match/splash.module.scss';
import * as style from 'styles/components/match/page/header.module.scss';
import { LangContext } from 'context/LangContext';
import Image from 'components/utils/Image';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import { MATCH_STATUS } from 'data/constants';

const MatchHeader = ({ match, matchEvents }) => {
    const ref = React.useRef();
    const { lang } = useContext(LangContext);

    const [timelineWidth, setTimelineWidth] = React.useState(null);

    React.useEffect(() => {
        if (ref.current) {
            const computed = window
                .getComputedStyle(ref.current)
                .getPropertyValue('inline-size')
                .slice(0, 3);
            setTimelineWidth(computed);
        }

        // eslint-disable-next-line consistent-return
        window.addEventListener('resize', () => {
            if (window.innerWidth < 850) return setTimelineWidth(136);
            if (window.innerWidth > 850 && window.innerWidth < 1200) return setTimelineWidth(350);
            if (window.innerWidth > 1200) return setTimelineWidth(530);
        });
    }, []);

    const {
        attendance,
        competition,
        external,
        externalScore,
        headerBanner,
        local,
        localScore,
        location,
        matchStatus,
        primarySponsor,
        secondarySponsor,
        startEventDate,
    } = match;

    const AXS_LINK = match.ticketsUrl;
    const SALES_START_DATE = new Date(match.ticketsSellStartTime);
    const MATCH_DATE = new Date(match.startEventDate);
    const SALES_SOLD_OUT = match.ticketsSoldOut;
    const FREE_ADMISSION = match.freeAdmission;
    const IS_CUSTOM_TEXT = match.isCustomText;
    const CUSTOM_TEXT = match.customText;

    const IS_HOME_MATCH = match.local.isHammarby;

    const buildButton = () => {
        if (match.matchStatus === MATCH_STATUS.FINISHED) return null;

        if (IS_CUSTOM_TEXT) {
            if( !CUSTOM_TEXT ) {
                return (
                    <button type="button" className="inactive cta-white" aria-label={<FormattedMessage id="match.notForSale" />}>
                        <FormattedMessage id="match.notForSale" />
                    </button>
                );
            }
            return (
                <button type="button" className="inactive cta-white" aria-label={CUSTOM_TEXT}>
                    {CUSTOM_TEXT}
                </button>
            );
        }

        if (FREE_ADMISSION) {
            return (
                <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('match.freeAdmission', lang)}>
                    <FormattedMessage id="match.freeAdmission" />
                </button>
            );
        }

        if (SALES_SOLD_OUT || SALES_START_DATE > MATCH_DATE) {
            return (
                <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('match.soldOut', lang)}>
                    <FormattedMessage id="match.soldOut" />
                </button>
            );
        }

        if (SALES_START_DATE && SALES_START_DATE < MATCH_DATE) {
            if (SALES_START_DATE > new Date()) {
                return (
                    <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('buyTickets.salesStart', lang)}>
                        <FormattedMessage id="buytickets.salesStart" />:{' '}
                        {SALES_START_DATE.toLocaleDateString(lang.iso, {
                            day: 'numeric',
                            month: 'short',
                        })}
                    </button>
                );
            }
            if (AXS_LINK) {
                return (
                    <a href={AXS_LINK} className="cta-white" target="_blank" rel="noreferrer" aria-label={getFormattedMessage('match.buyTicket', lang)}>
                        <FormattedMessage id="match.buyTicket" />
                    </a>
                );
            }
        }
        return (
            <button type="button" className="inactive cta-white" aria-label={getFormattedMessage('match.notForSale', lang)}>
                <FormattedMessage id="match.notForSale" />
            </button>
        );
    };

    const formattedMatchDate = () => {
        const date = new Date(startEventDate);
        return `${date.toLocaleDateString('sv', {
            weekday: 'long',
        })} ${date.getDate()} ${date.toLocaleString('sv', {
            month: 'long',
        })} ${date.getFullYear()} ${date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'Europe/Stockholm',
        })}`;
    };

    const renderer = ({ completed, formatted }) => {
        if (completed) {
            return (
                <span className={countdownStyle.splash__count__completed}>
                    <FormattedMessage id="match.matchStarted" />
                </span>
            );
        }
        return (
            <>
                <p className="text-sm-mobile text-lg uppercase">
                    <FormattedMessage id="match.matchStart" />
                </p>
                <div className={style.matchHeaderCounter__wrapper}>
                    {formatted.days && (
                        <div className={style.matchHeaderCounter__item}>
                            <span className={style.matchHeaderCounter__number}>
                                {formatted.days}
                            </span>
                            <span
                                className={`${style.matchHeaderCounter__info} text-md-mobile text-darker`}
                            >
                                DD
                            </span>
                        </div>
                    )}
                    <div className={style.matchHeaderCounter__item}>
                        <span className={style.matchHeaderCounter__number}>{formatted.hours}</span>
                        <span
                            className={`${style.matchHeaderCounter__info} text-md-mobile text-darker`}
                        >
                            HH
                        </span>
                    </div>
                    <span className={style.matchHeaderCounter__colon}>:</span>
                    <div className={style.matchHeaderCounter__item}>
                        <span className={style.matchHeaderCounter__number}>
                            {formatted.minutes}
                        </span>
                        <span
                            className={`${style.matchHeaderCounter__info} text-md-mobile text-darker`}
                        >
                            MM
                        </span>
                    </div>
                    <span className={style.matchHeaderCounter__colon}>:</span>
                    <div className={style.matchHeaderCounter__item}>
                        <span className={style.matchHeaderCounter__number}>
                            {formatted.seconds}
                        </span>
                        <span
                            className={`${style.matchHeaderCounter__info} text-md-mobile text-darker`}
                        >
                            SS
                        </span>
                    </div>
                </div>
            </>
        );
    };

    // eslint-disable-next-line consistent-return
    const renderMatchActionItem = (eventType) => {
        // eslint-disable-next-line default-case
        switch (eventType) {
            case 'goal':
                return (
                    <i className={`ico-ball ${style.matchHeader__ballIcon}`} aria-hidden="true" />
                );
            case 'switch-players':
                return (
                    <div className={style.matchHeader__arrowsComponent}>
                        <i
                            className={`ico-arrow ${style.matchHeader__arrowRight} ${style.matchHeader__redArrow}`}
                            aria-hidden="true"
                        />
                        <i
                            className={`ico-arrow ${style.matchHeader__arrowLeft}`}
                            aria-hidden="true"
                        />
                    </div>
                );
            case 'yellow-card':
                return <div className={style.matchHeader__yellowCard} />;
            case 'red-card':
                return <div className={style.matchHeader__redCard} />;
        }
    };

    return (
        <div className={`bg ${style.matchHeader}`}>
            {headerBanner && <Image className={style.matchHeaderImage} image={headerBanner} />}
            <div className={`wrapper ${style.matchHeader__content}`}>
                <span className={`text-semibold ${style.matchHeader__startEventDate}`}>
                    {formattedMatchDate()}
                </span>
                <span className={`text-xs text-semibold ${style.matchHeader__competition}`}>
                    {competition?.title.toUpperCase()}
                </span>
                {attendance && (
                    <span className="text-semibold text-xs">
                        <FormattedMessage id="match.attendance" />: {attendance}
                    </span>
                )}
                <div className={`text-xs text-semibold ${style.matchHeader__location}`}>
                    <i className="ico-pin" aria-hidden="true" />
                    <span>{location.toUpperCase()}</span>
                </div>
                <div className={`text-lg text-bold ${style.matchHeader__teams}`}>
                    <div className={style.matchHeader__localTeam}>
                        <span className="text">{local.title}</span>
                        {local.image && (
                            <ImageGatsby {...local.image} alt={local.title} title={local.title} />
                        )}
                    </div>
                    {matchStatus !== MATCH_STATUS.PLANNED && matchStatus !== MATCH_STATUS.CANCELLED ? (
                        <div className={`text-lg ${style.matchHeader__score}`}>
                            <div
                                className={`title-sm ${
                                    local.isHammarby
                                        ? style.matchHeader__scoreHammarby
                                        : style.matchHeader__scoreOpponent
                                }`}
                            >
                                {localScore}
                            </div>
                            <div
                                className={`title-sm ${
                                    external.isHammarby
                                        ? style.matchHeader__scoreHammarby
                                        : style.matchHeader__scoreOpponent
                                }`}
                            >
                                <span>{externalScore}</span>
                            </div>
                        </div>
                    ) : (
                        <div className={`text-lg ${style.matchHeader__score}`}>-</div>
                    )}
                    <div className={style.matchHeader__externalTeam}>
                        {external.image && (
                            <ImageGatsby
                                {...external.image}
                                alt={external.title}
                                title={external.title}
                            />
                        )}
                        <span>{external.title}</span>
                    </div>
                </div>

                <div className={style.matchHeader__centralPart}>

                    {matchStatus === MATCH_STATUS.PLANNED && (
                        <div className={style.matchHeaderCounter}>
                            <Countdown date={startEventDate} renderer={renderer} zeroPadTime={2} />
                        </div>
                    )}

                    {/* Show Home Game Actions */}
                    {matchStatus !== MATCH_STATUS.PLANNED && matchStatus !== MATCH_STATUS.CANCELLED &&
                        matchEvents.length > 0 && IS_HOME_MATCH && (
                            <div className={style.matchHeader__actions}>
                                <div className={`text-semibold ${style.matchHeader__localActions}`}>
                                    {matchEvents.slice(0).reverse().map(
                                        (event) =>
                                            event.club?.isHammarby &&
                                            event.player_reference &&
                                            event.type.name === 'goal' && (
                                                <div className={style.matchHeader__localAction}>
                                                    <span className={style.matchHeader__localActionName}>{event.player_reference.lastName}</span>
                                                    <span className={style.matchHeader__localEventMinute}>{`${event.minute}'`}</span>
                                                    {renderMatchActionItem(event.type.name)}
                                                </div>
                                            ),
                                    )}
                                </div>
                                <div className={`text-semibold ${style.matchHeader__externalActions}`}>
                                    {matchEvents.slice(0).reverse().map(
                                        (event) =>
                                            !event.club?.isHammarby &&
                                            event.player_reference &&
                                            event.type.name === 'goal' && (
                                                <div className={style.matchHeader__externalAction}>
                                                    {renderMatchActionItem(event.type.name)}
                                                    <span className={style.matchHeader__externalEventMinute}>{`${event.minute}'`}</span>
                                                    <span>{event.player_reference.lastName}</span>
                                                </div>
                                            ),
                                    )}
                                </div>
                            </div>
                        )
                    }
                    {/* Show Away Game Actions */}
                    {matchStatus !== MATCH_STATUS.PLANNED && matchStatus !== MATCH_STATUS.CANCELLED &&
                        matchEvents.length > 0 && !IS_HOME_MATCH && (
                            <div className={style.matchHeader__actions}>
                                <div className={`text-semibold ${style.matchHeader__localActions}`}>
                                    {matchEvents.slice(0).reverse().map(
                                        (event) =>
                                            !event.club?.isHammarby &&
                                            event.player_reference &&
                                            event.type.name === 'goal' && (
                                                <div className={style.matchHeader__localAction}>
                                                    <span className={style.matchHeader__localActionName}>{event.player_reference.lastName}</span>
                                                    <span className={style.matchHeader__localEventMinute}>{`${event.minute}'`}</span>
                                                    {renderMatchActionItem(event.type.name)}
                                                </div>
                                            ),
                                    )}
                                </div>
                                <div className={`text-semibold ${style.matchHeader__externalActions}`}>
                                    {matchEvents.slice(0).reverse().map(
                                        (event) =>
                                            event.club?.isHammarby &&
                                            event.player_reference &&
                                            event.type.name === 'goal' && (
                                                <div className={style.matchHeader__externalAction}>
                                                    {renderMatchActionItem(event.type.name)}
                                                    <span className={style.matchHeader__externalEventMinute}>{`${event.minute}'`}</span>
                                                    <span>{event.player_reference.lastName}</span>
                                                </div>
                                            ),
                                    )}
                                </div>
                            </div>
                        )
                    }
                </div>
                {/* End central part */}
                {buildButton()}
                {matchStatus === MATCH_STATUS.FINISHED && (
                    <div className={style.matchHeader__timeline}>
                        <div className={style.matchHeader__teamsLogos}>
                            {local.image && (
                                <div className={style.matchHeader__teamsLogo}>
                                    <ImageGatsby {...local.image} alt={local.title} />
                                </div>
                            )}
                            {external.image && (
                                <div className={style.matchHeader__teamsLogo}>
                                    <ImageGatsby {...external.image} alt={external.title} />
                                </div>
                            )}
                        </div>
                        {IS_HOME_MATCH && (
                        <div className={style.matchHeader__firstPeriod}>
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        event.club?.isHammarby &&
                                        event.minute <= 45 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: `${
                                                        (timelineWidth * event.minute) / 100
                                                    }px`,
                                                }}
                                            >
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                            <div className={style.matchHeader__firstPeriodLine} ref={ref} />
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        !event.club?.isHammarby &&
                                        event.minute <= 45 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: `${
                                                        (timelineWidth * event.minute) / 100
                                                    }px`,
                                                }}
                                            >
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                        </div>
                        )}
                        {!IS_HOME_MATCH && (
                        <div className={style.matchHeader__firstPeriod}>
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        !event.club?.isHammarby &&
                                        event.minute <= 45 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: `${
                                                        (timelineWidth * event.minute) / 100
                                                    }px`,
                                                }}
                                            >
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                            <div className={style.matchHeader__firstPeriodLine} ref={ref} />
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        event.club?.isHammarby &&
                                        event.minute <= 45 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: `${
                                                        (timelineWidth * event.minute) / 100
                                                    }px`,
                                                }}
                                            >
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                        </div>
                        )}
                        {IS_HOME_MATCH && (
                        <div className={style.matchHeader__secondPeriod}>
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        event.club?.isHammarby &&
                                        event.minute > 45 &&
                                        event.minute <= 90 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: `${
                                                        (timelineWidth * event.minute) / 100
                                                    }px`,
                                                }}
                                            >
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                            <div className={style.matchHeader__secondPeriodLine} />
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        !event.club?.isHammarby &&
                                        event.minute > 45 &&
                                        event.minute <= 90 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: `${
                                                        (timelineWidth * event.minute) / 100
                                                    }px`,
                                                }}
                                            >
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                        </div>
                        )}
                        {!IS_HOME_MATCH && (
                        <div className={style.matchHeader__secondPeriod}>
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        !event.club?.isHammarby &&
                                        event.minute > 45 &&
                                        event.minute <= 90 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: `${
                                                        (timelineWidth * event.minute) / 100
                                                    }px`,
                                                }}
                                            >
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                            <div className={style.matchHeader__secondPeriodLine} />
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        event.club?.isHammarby &&
                                        event.minute > 45 &&
                                        event.minute <= 90 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: `${
                                                        (timelineWidth * event.minute) / 100
                                                    }px`,
                                                }}
                                            >
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                        </div>
                        )}
                        {IS_HOME_MATCH && (
                        <div className={style.matchHeader__extension}>
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        event.club?.isHammarby &&
                                        event.minute > 90 && (
                                            <div style={{ position: 'absolute' }}>
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                            <div className={style.matchHeader__extensionLine} />
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        !event.club?.isHammarby &&
                                        event.minute > 90 && (
                                            <div style={{ position: 'absolute' }}>
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                        </div>
                        )}
                        {!IS_HOME_MATCH && (
                        <div className={style.matchHeader__extension}>
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        !event.club?.isHammarby &&
                                        event.minute > 90 && (
                                            <div style={{ position: 'absolute' }}>
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                            <div className={style.matchHeader__extensionLine} />
                            <div className={style.matchHeader__actionsIcons}>
                                {matchEvents.map(
                                    (event) =>
                                        event.club?.isHammarby &&
                                        event.minute > 90 && (
                                            <div style={{ position: 'absolute' }}>
                                                {renderMatchActionItem(event.type.name)}
                                            </div>
                                        ),
                                )}
                            </div>
                        </div>
                        )}
                    </div>
                )}
                <div className={style.matchHeader__sponsors}>
                    {(primarySponsor || secondarySponsor) && (
                        <span className={`text-xs text-medium ${style.matchHeader__sponsorsHeading}`}>
                            <FormattedMessage id="match.partnerSponsoredBy" />
                        </span>
                    )}
                    <div className={style.matchHeader__logos}>
                        {primarySponsor && (
                            <a
                                href={primarySponsor.url}
                                className={style.matchHeader__logo}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <ImageGatsby
                                    {...primarySponsor.logoWhite}
                                    alt={primarySponsor.title}
                                    title={primarySponsor.title}
                                />
                            </a>
                        )}
                        {secondarySponsor && (
                            <a
                                href={secondarySponsor.url}
                                className={style.matchHeader__logo}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <ImageGatsby
                                    {...secondarySponsor.logoWhite}
                                    alt={secondarySponsor.title}
                                    title={secondarySponsor.title}
                                />
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MatchHeader;
