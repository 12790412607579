import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';

import * as style from 'styles/components/account/accountLayout.module.scss';
import Consent from 'components/utils/Consent';
import ConsentModal from 'components/utils/ConsentModal';
import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import useAccountInfoBoxes from 'data/queries/useAccountInfoBoxes';
import TextEditorRender from 'components/utils/TextEditorRender';
import CustomLink from 'components/utils/CustomLink';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const AccountLayout = ({ pageContext, children }) => {
    const { lang } = useContext(LangContext);
    const { userName, user, isSignedIn, signOut } = useAuth();
    const [showModal, setShowModal] = useState(false);

    const userInitials = isSignedIn ? userName.split(' ').map(n => n.charAt(0)) : null;

    const activeLinkType = pageContext.page.content.type;
    const currentSlug = pageContext.page.slug.current;

    const MENU = [
        {
            route: 'accountTickets',
            icon: 'tickets',
            title: 'Mina biljetter',
            sub: false,
        },
        {
            route: 'accountPass',
            icon: 'pass',
            title: 'Mitt säsongskort',
            sub: false,
        },
        /*
        {
            route: 'accountMembership',
            icon: 'star',
            title: 'Mitt medlemskap',
            sub: false,
        },
        {
            route: 'accountMembershipOffers',
            icon: 'discount',
            title: 'Bajendealen',
            sub: true,
        },
        */
        {
            route: 'account',
            icon: 'user',
            title: 'Mitt konto',
            sub: false,
        },
    ];

    const infoBoxes = useAccountInfoBoxes();

    useEffect(() => {
        if (isSignedIn === false) {
            navigate('/login');
        }

        if (isSignedIn && !user.attributes['custom:consent_membership']) {
            setShowModal(true);
        }

    }, [isSignedIn, user]);

    return (
        <>
            <ConsentModal show={showModal} onClose={() => setShowModal(false)}>
                <Consent />
            </ConsentModal>
            <div className={style.account__headerSection}>
                <div className={`wrapper ${style.account__header}`}>
                    {!!userInitials &&
                        <div className={style.account__pictureName}>
                            {userInitials.map(initial => (initial))}
                        </div>
                    }

                    {isSignedIn && (
                        <div className={style.account__userInfo}>
                            <div className={style.account__userName}>
                                {userName.split(' ').map((name) => (
                                    <div key={name}>{name}</div>
                                ))}
                            </div>
                            <p className={style.account__userMember}>
                                <FormattedMessage id="account.press" />{' '}
                                <a href="https://entry.sportadmin.se/groupsOverview?uid=zhtwPr&pageid=194" target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.becomeMember', lang)}>
                                    <FormattedMessage id="account.here" />
                                </a>{' '}
                                <FormattedMessage id="account.becomeMember" />
                            </p>
                        </div>
                    )}

                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <button type="button" className={style.account__logOutButton} onClick={signOut} aria-label={getFormattedMessage('global.logOut', lang)}>
                        <FormattedMessage id="global.logOut" />
                    </button>
                </div>
            </div>

            <div className={`wrapper ${style.account__container}`}>
                <aside className={style.account__navContainer}>
                    <nav>
                        <ul>
                            {MENU.map((item) => (
                                <li key={item.route}>
                                    <CustomLink
                                        namedRoute={item.route}
                                        className={`${style.account__menu} ${activeLinkType === item.route ? `${style.account__activeMenuItem}` : ''}`}
                                    >
                                        <i className={`ico-${item.icon}`} aria-hidden="true" />
                                        {item.title}
                                    </CustomLink>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside>

                <main className={style.account__content}>
                    {children}
                </main>

                <div className={style.account__infoBoxes}>
                    {infoBoxes
                        .filter(infoBox => infoBox.pages.some(page => page.slug.current === currentSlug))
                        .map(infoBox => (
                            <div className={style.account__infoBox} key={infoBox._id}>
                                <TextEditorRender content={infoBox.boxContent[lang.translationKey]._rawContent} />
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default AccountLayout;
