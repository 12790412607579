import React, { useContext, useEffect, useState } from 'react';

import * as style from 'styles/components/account/accountLayout.module.scss';
import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import { AXS_EMAIL_ATTRIBUTES, AXS_URLS } from 'data/constants';
import fetchAPI from 'data/api';
import useHammarbyClub from 'data/queries/useHammarbyClub';
import useAccountBanners from 'data/queries/useAccountBanners';
import AccountLayout from 'components/account/Layout';
import AxsEmailRegister from 'components/account/AxsEmailRegister';
import SeasonQueueItem from 'components/account/SeasonQueueItem';
import SeasonPassItem from 'components/account/SeasonPassItem';
import AccountBanner from 'components/account/Banner';
import Loader from 'components/utils/Loader';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

// Used to filter season pass with this mapping between the API category and the team axsType.
const seasonPassCategoryMap = {
    'Men': 'men',
    'Women': 'women',
};

const AccountSeasonPass = ({ pageContext }) => {
    const { lang } = useContext(LangContext);
    const { isSignedIn, user } = useAuth();

    const club = useHammarbyClub();
    const banners = useAccountBanners();

    const [filteredTeam, setFilteredTeam] = useState({});
    const [filteredBanners, setFilteredBanners] = useState([]);

    const [seasonPassQueueData, setSeasonPassQueueData] = useState([]);
    const [isErrorLoadingSeasonPassQueue, setIsErrorLoadingSeasonPassQueue] = useState(false);

    const [allSeasonPasses, setAllSeasonPasses] = useState(null);
    const [filteredSeasonPasses, setFilteredSeasonPasses] = useState([]);
    const [isNoSeasonPass, setIsNoSeasonPass] = useState(null);
    const [hadPassLastSeason, setHadPassLastSeason] = useState(false);
    const [isErrorLoadingSeasonPass, setIsErrorLoadingSeasonPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingQueue, setLoadingQueue] = useState(false);

    const [axsUrl, setAxsUrl] = useState(AXS_URLS.MEN);

    // Get the teams filters and select the first one
    useEffect(() => {
        const teams = club.teams.filter(item => (
            item.title && item.title[lang.translationKey] && item.allowSeasonPassSales
        ));

        let selectedTeam = teams[0];

        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.get('selectedTeam') !== null) {
                selectedTeam = teams.find((item) => item._id === urlParams.get('selectedTeam')) || teams[0];
            }
        }

        setFilteredTeam(selectedTeam);
    }, [club, lang.translationKey]);

    // Get the season ticket queue information
    useEffect(() => {
        if (!isSignedIn) return;

        setLoadingQueue(true);

        fetchAPI(process.env.GATSBY_API_SEASON_PASS_QUEUE, user?.signInUserSession.idToken.jwtToken)
            .then(seasonPassQueue => {
                const queueArray = Array.isArray(seasonPassQueue) ? seasonPassQueue : [seasonPassQueue];
                const availableSeasonPassQueueData = queueArray
                    .map(queueData => ({
                        email: queueData.email,
                        position: queueData.position,
                        validThrough: queueData.valid_through,
                        isActive: queueData.active,
                        queueStatus: queueData.status,
                    }));
                    setSeasonPassQueueData(availableSeasonPassQueueData[0]);
                setLoadingQueue(false);
            })
            .catch((error) => {
                console.error(error);
                setIsErrorLoadingSeasonPassQueue(true);
                setLoadingQueue(false);
            });
    }, [isSignedIn, pageContext]);

    // Get the season passes and link there tickets them to the matches
    useEffect(() => {
        if (!isSignedIn) return;

        setLoading(true);

        fetchAPI(process.env.GATSBY_API_SEASON_PASS, user?.signInUserSession.idToken.jwtToken)
            .then((seasonPasses) => {
                setHadPassLastSeason(seasonPasses.hadPassLastSeason);
                const availableSeasonPasses = seasonPasses.seasonTickets
                    .filter(sp => new Date(sp.season.seasonYear).getFullYear() >= new Date().getFullYear())
                    .map(seasonPass => {
                        const seasonPassAvailableTickets = seasonPass.tickets
                            .map((ticket) => {
                                if (!ticket.event.matchId) return null;

                                const match = pageContext.allMatches.find(m => m._id === ticket.event.matchId);
                                if (!match) return null;

                                return {
                                    ...ticket,
                                    matchData: {
                                        team: match.team,
                                        local: match.local,
                                        external: match.external,
                                        location: match.location,
                                        date: match.startEventDate,
                                    },
                                };
                            })
                            .filter(ticket => ticket);

                        return { ...seasonPass, Tickets: seasonPassAvailableTickets };
                    });

                setAllSeasonPasses(availableSeasonPasses);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsErrorLoadingSeasonPass(true);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn, pageContext]);

    // Manage season passes displayed with the team filter
    useEffect(() => {
        if (!allSeasonPasses) return;

        if (allSeasonPasses.length === 0) {
            setIsNoSeasonPass(true);
            return;
        }

        const filtered = allSeasonPasses
            .filter(sp => seasonPassCategoryMap[sp.season.category] === filteredTeam.axsType)
            .sort((a, b) => new Date(b.season.seasonYear) - new Date(a.season.seasonYear));

        setFilteredSeasonPasses(filtered);
        setIsNoSeasonPass(filtered.length === 0);

        setAxsUrl(filteredTeam.axsType === 'men' ? AXS_URLS.MEN : AXS_URLS.WOMEN);
    }, [filteredTeam, allSeasonPasses]);

    // Manage the banners showed with the team filter
    useEffect(() => {
        const filtered = banners?.filter(bannerForFilter => (
            bannerForFilter.pages.some(page => page.slug.current === pageContext.page.slug.current) &&
            bannerForFilter.teams.some(team => team._id === filteredTeam._id)
        ));

        setFilteredBanners(filtered);
    }, [filteredTeam, banners, pageContext]);

    return (
        <AccountLayout pageContext={pageContext}>
            <ul className="tabs tabs--NoScrollDesk">
                {club.teams
                    .filter(
                        (item) =>
                            item.title &&
                            item.title[lang.translationKey] &&
                            item.allowSeasonPassSales,
                    )
                    .map((item, index) => (
                        <li key={index}>
                            <button
                                onClick={() => setFilteredTeam(item)}
                                className={filteredTeam._id === item._id ? 'active' : ''}
                                type="button"
                            >
                                {item.title[lang.translationKey]}
                            </button>
                        </li>
                    ))}
            </ul>

            {/* Display connect forms */}
            {isSignedIn && !user.attributes[AXS_EMAIL_ATTRIBUTES[filteredTeam.axsType]] && (
                <>
                    {filteredTeam.axsType === 'men' &&
                        <AxsEmailRegister teamType="men" teamId={filteredTeam._id}/>
                    }
                    {filteredTeam.axsType === 'women' &&
                        <AxsEmailRegister teamType="women" teamId={filteredTeam._id} />
                    }
                </>
            )}

            {/* Display Season passes */}
            {isSignedIn && user.attributes[AXS_EMAIL_ATTRIBUTES[filteredTeam.axsType]] && (
                <div>
                    {loading && <Loader message={getFormattedMessage('account.seasonPass.loading', lang)} />}

                    {filteredSeasonPasses.length !== 0 && filteredSeasonPasses.map((sp) => (
                        <div key={sp.id}>
                            <SeasonPassItem seasonPass={sp} axsUrl={axsUrl} />
                        </div>
                    ))}

                    {/* Display no season pass bought, but had seasonpass last year message for men */}
                    {isNoSeasonPass && hadPassLastSeason && filteredTeam.axsType === 'men' && (
                        <div className={`${style.account__renewSeasonCardBox} bg`}>
                            <p className={`${style.account__seasonLabel}`}><FormattedMessage id="account.seasonPass.seasonPass" /></p>
                            <div className={style.account__innerBox}>
                                <p>
                                    <FormattedMessage id="account.seasonPass.noSeasonPass" /> Du kommer att se ditt säsongskort här när vi närmar oss start.
                                    <a href="https://hammarbyherr.ebiljett.nu/account/reservations" target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.seasonPass.buySeasonPass', lang)}>
                                        <FormattedMessage id="account.seasonPass.buySeasonPass" />
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}

                    {/* Display no season pass bought and had no seasonpass last year message for men */}
                    {isNoSeasonPass && !hadPassLastSeason && filteredTeam.axsType === 'men' && (
                        <div className={`${style.account__renewSeasonCardBox} bg`}>
                            <p className={`${style.account__seasonLabel}`}><FormattedMessage id="account.seasonPass.seasonPass" /></p>
                            <div className={style.account__innerBox}>
                                <p>
                                    <FormattedMessage id="account.seasonPass.noSeasonPass" /> Du kommer att se ditt säsongskort här när vi närmar oss start.
                                    <a href="https://hammarbyherr.ebiljett.nu/account/reservations" target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.seasonPass.buySeasonPass', lang)}>
                                        <FormattedMessage id="account.seasonPass.buySeasonPass" />
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}

                    {/* Display no season pass bought message for women */}
                    {isNoSeasonPass && filteredTeam.axsType === 'women' && (
                        <div className={`${style.account__renewSeasonCardBox} bg`}>
                            <p className={`${style.account__seasonLabel}`}><FormattedMessage id="account.seasonPass.seasonPass" /></p>
                            <div className={style.account__innerBox}>
                                <p>
                                    <FormattedMessage id="account.seasonPass.noSeasonPass" />
                                    <a href="https://hammarbydam.ebiljett.nu/Account?ReturnUrl=%2Faccount%2Freservations" target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.seasonPass.buySeasonPass', lang)}>
                                        <FormattedMessage id="account.seasonPass.buySeasonPass" />
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}

                    {/* Display Error message */}
                    {isErrorLoadingSeasonPass && (
                        <div className={style.account__errorTicketsBox}>
                            <p>
                                <span><FormattedMessage id="account.seasonPass.errorLoadingSeasonPass" /></span>
                                <a href={axsUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.seasonPass.seeSeasonPassInAxs', lang)}>
                                    <FormattedMessage id="account.seasonPass.seeSeasonPassInAxs" />
                                </a>
                            </p>
                        </div>
                    )}
                </div>
            )}

            {/* Display seasonal tickets queue position */}
            {isSignedIn && user.attributes[AXS_EMAIL_ATTRIBUTES[filteredTeam.axsType]] && filteredTeam.axsType === 'men' && (
                <div>
                    {loadingQueue && <Loader message={getFormattedMessage('account.seasonPassQueue.loading', lang)} />}
                    {!loadingQueue && !isErrorLoadingSeasonPassQueue && seasonPassQueueData && (
                        <SeasonQueueItem seasonPassQueueData={seasonPassQueueData} />
                    )}

                    {/* Display Error message */}
                    {isErrorLoadingSeasonPassQueue && (
                        <div>
                            <FormattedMessage id="account.seasonPassQueue.errorLoadingSeasonPassQueue" />
                        </div>
                    )}
                </div>
            )}

            {/* Display banners */}
            {filteredBanners && filteredBanners.map(banner => (
                <div key={banner.id}>
                    <AccountBanner banner={banner} />
                </div>
            ))}
        </AccountLayout>
    );
};

export default AccountSeasonPass;
